import { Injectable } from '@angular/core';
import { TokenDto } from '../../models/franchise/franchise.model';

@Injectable({
  providedIn: 'root'
})
export class HelpersService {

  getExpirationDuration(tokenDto: TokenDto): number {
    if (tokenDto.expires)
      return new Date(tokenDto.expires).getTime() - new Date().getTime();
    return 0;
  }
  userIsAuthenticated(tokenDto: TokenDto): boolean {
    if (tokenDto && tokenDto.token) {
      return this.getExpirationDuration(tokenDto) > 5 * 1000;
    }
    return false;
  }
}
